<template>
  <div class="no-data">
    <img class="img" src="../assets/img/noData.png" :width="width" :height="height" alt="">
    <p class="text">{{noDataText}}</p>
  </div>
</template>

<script>
export default {
  name: "no-data",
  props: {
    width: {
      type: String,
      default: '300'
    },
    height: {
      type: String,
      default: '300'
    },
    noDataText: {
      type: String,
      default: '当前暂无相关数据哦～'
    }
  }
}
</script>

<style scoped>
.no-data{width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 40px 0}
.img{display: block;margin: 0 auto;}
.text{font-family: PingFangSC-Medium;font-size: 14px;color: #999999;letter-spacing: 0;text-align: center;line-height: 14px;margin-top: 20px}
</style>
